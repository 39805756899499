.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.logo {
  height: 320px;

  @media only screen and (min-width: 1440px) {
    height: 532px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 32px;
}

.title {
  text-align: center;
  font-size: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
}

.description {
  text-align: center;
  font-size: 14px;
  margin: 16px 0;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.ship-blue {
  color: #137eb0;
}

.ship-green {
  color: #388640;
}

.slogan {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  bottom: 72px;
  font-size: 12px;
  gap: 4px;
  margin: 0 32px;
  text-align: justify;

  @media only screen and (min-width: 1440px) {
    font-size: 16px;
  }
}
