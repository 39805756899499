html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #ddd;
  color: #494848;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: 900;
}

p {
  font-weight: 400;
}

a {
  text-decoration: none;
  color: #388640;
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #137eb0;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
