.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 10px solid;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;

  @media only screen and (min-width: 1920px) {
    width: 96px;
    height: 96px;
  }
}

@keyframes animloader {
  0% {
    border-color: #137eb0 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0)
      rgba(255, 255, 255, 0);
  }
  33% {
    border-color: #137eb0 #388640 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }
  66% {
    border-color: #137eb0 #388640 #137eb0 rgba(255, 255, 255, 0);
  }
  100% {
    border-color: #137eb0 #388640 #137eb0 #388640;
  }
}
